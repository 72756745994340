<template>
  <div class="card-body">
    <div class="row p-3">
      <div class="col-lg-12">
        <div class="row gap-5">
          <div v-if="hasOption" class="col-lg-12">
            <div class="row g-4">
              <div class="col-xl-9 col-lg-8 col-sm-8">
                <div class="d-flex justify-content-sm-start" style="column-gap: 10px">
                  <div class="search-box">
                    <input type="text" class="form-control"
                           @keyup.enter="handleSearch" v-model="state.search"
                           :placeholder='`${$t("t-search")}`'/>
                    <i class="ri-search-line search-icon"></i>
                  </div>
                  <div>
                    <b-button variant="primary" class="waves-effect waves-light" @click="handleSearch">
                      <div class="flex">
                        {{ $t("t-search") }}
                      </div>
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-sm-4 d-flex justify-content-end">
                <div class="d-flex justify-content-end align-items-center"
                     style="column-gap: 5px; font-size: 12px">
                  <select name="example_length" aria-controls="example" @change="handleChangePerPage"
                          class="form-select form-control" style="max-width: 85px">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <span style="min-width: 65px">{{ $t('entries_page') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div :class="`table-responsive ${tableClassName}`">
              <table class="test table align-middle table-nowrap mb-0 table-hover table-bordered">
                <thead class="table-light">
                <tr>
                  <template v-for="(head, index) in state.fields" :key="`head_${index}`">
                    <th v-if="head.getShowColumn ? head.getShowColumn(state.items.length > 0) : true"
                        data-sort="currency_name" scope="col">
                      <div
                        class="position-relative d-flex justify-content-between align-items-center">
                        <div :class="`${defaultHeaderClass} ${head.headerClass}`">{{
                            head.label
                          }}
                        </div>
                        <div v-if="head.sortable"
                             @click="sort(head.sortKey ? head.sortKey : head.key)"
                             class="sortClass">

                          <template
                            v-if="head.sortKey ? head.sortKey !== state.query.sortBy : head.key !== state.query.sortBy">
                            <i class="ri-expand-up-down-fill font-16 cursor-pointer"
                               style="color: #acadaf"></i>
                          </template>

                          <template v-else>
                            <i
                              class="ri-arrow-up-s-fill cursor-pointer d-flex align-items-center justify-content-center font-16"
                              style="padding: 0 4px; "
                              :class="getClassActive(head.sortKey ? head.sortKey : head.key, 'ASC')"/>
                            <i style="padding: 0 4px;"
                               class="ri-arrow-down-s-fill cursor-pointer d-flex align-items-center justify-content-center font-16"
                               :class="getClassActive(head.sortKey ? head.sortKey : head.key, 'DESC')"/>
                          </template>

                        </div>
                      </div>

                    </th>
                  </template>

                </tr>
                </thead>
                <tbody>
                <template v-if="!state.loading">
                  <tr v-for="(row, index) in state.items" :key="`row_${index}`">
                    <td :style="`max-width: ${cel.maxWidth}px`"
                        :class="cel.dataClass"
                        v-for="(cel, indexCell) in state.fields" :key="`cel_${indexCell}`"
                        data-bs-toggle="tooltip" data-bs-placement="bottom"
                        class="text-truncate fs-12 fw-medium text-normal"
                        :title="cel.key !== 'status' ? (cel.tooltip ? cel.tooltip(row) : getCellValue(row, cel)) : ''">
                      <div v-if="cel.renderCell" :class="cel.cellClass">
                        <component :is="cel.renderCell(row, index)"/>
                      </div>
                      <div v-else :class="`${defaultTdClass} ${cel.cellClass}`">{{
                          getCellValue(row, cel)
                        }}
                      </div>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="8" class="text-center p-5">
                    <Loading/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="w-100 mt-3" v-if="hasPaginate" style="border-bottom: 0; border-bottom-color: transparent;">
          <div class="d-flex justify-content-end">
            <Pagination :pagination="state.pagination" @onChangePage="paginate"/>
          </div>
        </div>
        <div v-if="state.items.length === 0 && !state.loading"
             :class="`d-flex justify-content-center align-items-center no-results mb-4 ${emptyTextClass}`">
          {{ emptyText }}
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import {reactive, onMounted, watch, toRefs, computed} from 'vue';
import axios from "@/helpers/axios";
import {cloneDeep, get} from 'lodash';
import Loading from "@/components/Loading.vue";
import Pagination from "@/components/pagination";
import i18n from '@/i18n';

// Define the props using toRefs to access them in the setup
const props = defineProps(
  {
    endpoint: {
      type: String,
      default: ''
    },
    query: {
      type: Object,
      default: () => {
      }
    },
    columns: {
      type: Array,
      default: () => []
    },
    dataTable: {
      type: Array,
      default: () => []
    },
    ref: {
      type: Object,
      required: false
    },
    emptyText: {
      type: String,
      default: i18n.global.t('t-no-data')
    },
    emptyTextClass: {
      type: String,
      default: ''
    },
    hasOption: {
      type: Boolean,
      default: true
    }
  }
);
const {endpoint, query, columns, dataTable} = toRefs(props);

// Your setup logic here
const state = reactive({
  fields: columns.value,
  items: dataTable.value,
  isBusy: false,
  search: '',
  pagination: null,
  loading: false,
  query: {
    search: '',
    page: 1,
    pageSize: 10,
    sortBy: '',
    sortOrder: 'ASC',
    ...query.value,
  }
});

const defaultTdClass = 'text-truncate'
const defaultHeaderClass = 'w-100'

const sort = (key) => {
  let typeDESC = true;
  if (state.query.sortBy === key) {
    typeDESC = !(state.query.sortOrder === 'DESC');
  }
  state.query.sortBy = key;
  state.query.sortOrder = typeDESC ? 'DESC' : 'ASC';
};

const getDataFromEndPoint = async () => {
  if (endpoint.value !== '') {
    state.loading = true;
    try {
      const res = await axios.get(endpoint.value, {params: {...state.query, search: state.search}});
      const data = Array.isArray(res) ? res : res?.data || []
      state.items = cloneDeep(data);
      state.pagination = cloneDeep(res?.pagination);
    } catch (e) {
      console.log('getDataFromEndPoint', e);
    } finally {
      state.loading = false;
    }
  }
};

const tableClassName = computed(() => {
  return state.items.length > 0 ? 'table-fixed-header' : 'hiddenOverflowX';
})


const getCellValue = (totalData, cell) => {
  return get(totalData, cell.key, '');
}

const refreshTable = () => {
  getDataFromEndPoint()
}

const paginate = (pageNumber) => {
  state.query.page = pageNumber;
}


onMounted(() => {
  getDataFromEndPoint();
});

watch(props.query, () => {
  state.query = {...state.query, ...props.query}
})

watch(
  () => state.query,
  () => {
    getDataFromEndPoint();
  },
  {deep: true}
);

const handleChangePerPage = (e) => {
  state.query.pageSize = e.target.value;
  state.query.page = 1
}

const getClassActive = (key, type) => {
  let display = 'd-block';
  if (state.query.sortBy === key && state.query.sortOrder !== type) {
    display = "d-none";
  }

  return display;
}


const handleSearch = () => {
  state.query.page = 1
  getDataFromEndPoint();
}

const setQuery = (query) => {
  state.query = query;
}

const reset = (params = {}) => {
  state.pagination = null

  state.query = {
    page: 1,
    pageSize: 10,
    search: '',
    sortBy: '',
    sortOrder: 'ASC',
    ...params
  }

  refreshTable()
}

const hasPaginate = computed(() => state.pagination && state.items.length > 0)

defineExpose({refreshTable, setQuery, getCellValue, sort, reset})

</script>

<script>
// Export the components

export default {
  components: {Loading, Pagination},
  setup() {
    return {
      ...toRefs(state),
    };
  },
};
</script>


<style scoped lang="scss">
.sort-active {
  background-color: #cccc;
  border-radius: 54%;
}

.font-16 {
  font-size: 16px;
}

.no-results {
  height: 100px;
  border: 1px solid var(--vz-border-color);
  border-top: none;
}

.table-fixed-header {
  width: 100%;
  max-height: 584px;
  overflow: auto;
}

.table-fixed-header thead tr th {
  position: sticky;
  top: -1px;
  z-index: 99;
}

.sortClass {
  position: relative;
  right: 0px;
  bottom: 0px;
}

.hiddenOverflowX {
  overflow: unset;
}

td {
  padding: 0.4rem 0.6rem;
}

.table ::v-deep {
  .badge {
    width: 109px;
    padding: 4.5px 0;
    font-size: 11px !important;
  }
}

::-webkit-scrollbar {
  display: none;
}

.test::-webkit-scrollbar {
  display: none;
}
</style>
