<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-3">
                <div class="card rounded-3">
                    <div class="card-header card-header-radius">
                        <h6 class="fs-16 mb-0 custom-h">{{ $t('publicAPI.register') }}</h6>
                    </div>
                    <div class="card-body">
                        <form class="row gap-2" @submit.prevent="createPublicAPI">
                            <div class="col-lg-12">
                                <div class="d-flex flex-column gap-1">
                                    <div>
                                        <label>{{ $t('publicAPI.companyName') }} <span class="text-danger">*</span></label>
                                        <input v-model="state.publicAPI.companyName"
                                            :placeholder="$t('placeholder.pleaseInput', { field: $t('publicAPI.companyName') })"
                                            type="text" class="form-control" maxlength="255" />
                                        <div v-if="v$.companyName.$error" class="invalid-feedback d-flex flex-column">
                                            <span v-if="v$.companyName.required.$invalid">{{
                                                v$.companyName.required.$message
                                            }}</span>
                                            <span
                                                v-if="v$.companyName.maxLength.$invalid && !v$.companyName.required.$invalid">{{
                                                    v$.companyName.maxLength.$message
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="d-flex flex-column gap-1">
                                    <div>
                                        <label>{{ $t('publicAPI.facilityName') }} <span class="text-danger">*</span></label>
                                        <v-select class="style-chooser" :filterable="false" @search="debounceSearchFacility"
                                            :reduce="facility => facility.value" :options="state.facilities" @close="selectFacilityClose"
                                            v-model="state.publicAPI.facilityId" @option:selected="selectedFacilityChange"
                                            :placeholder="$t('placeholder.pleaseSelect', { field: $t('publicAPI.facilityName') })">
                                            <template #no-options="{ search, searching, loading }">{{ $t('t-no-data')
                                            }}</template>
                                        </v-select>
                                        <div v-if="v$.facilityId.$error" class="invalid-feedback d-flex flex-column">
                                            <span v-if="v$.facilityId.required.$invalid">{{
                                                v$.facilityId.required.$message
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="state.apiId" class="col-lg-12">
                                <div class="d-flex flex-column gap-1">
                                    <div>
                                        <label>{{ $t('t-enable-disable') }} <span class="text-danger">*</span></label>
                                        <!-- Switches Color -->
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="status222"
                                                v-model="state.publicAPI.status">

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="d-flex flex-column gap-1">
                                    <div>
                                        <label>{{ $t('publicAPI.startDate') }} <span class="text-danger">*</span></label>
                                        <div class="form-icon right">
                                            <FlatPkr ref="startDatePicker" :config="startDateConfig"
                                                @change="startDateChange"
                                                :placeholder="$t('placeholder.pleaseSelect', { field: $t('publicAPI.startDate') })"
                                                v-model="state.publicAPI.startTime" class="form-control form-control-icon">

                                            </FlatPkr>
                                            <i class="bx bx-calendar-alt cursor-pointer" @click="onStartDateIconClick"></i>
                                        </div>
                                        <div v-if="v$.startTime.$error" class="invalid-feedback d-flex flex-column">
                                            <span v-if="v$.startTime.required.$invalid">{{
                                                v$.startTime.required.$message
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="d-flex flex-column gap-1">
                                    <div>
                                        <label>{{ $t('publicAPI.endDate') }}</label>
                                        <div class="form-icon right">
                                            <FlatPkr ref="endDatePicker" :config="endDateConfig"
                                                :placeholder="$t('placeholder.pleaseSelect', { field: $t('publicAPI.endDate') })"
                                                v-model="state.publicAPI.endTime" class="form-control form-control-icon">

                                            </FlatPkr>
                                            <i class="bx bx-calendar-alt cursor-pointer" @click="onEndDateIconClick"></i>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="d-flex flex-column gap-1">
                                    <div>
                                        <label>{{ $t('publicAPI.remarks') }}</label>
                                        <textarea class="form-control" v-model="state.publicAPI.note" rows="4"
                                            maxlength="255"></textarea>
                                        <div v-if="v$.note.$error" class="invalid-feedback d-flex flex-column">
                                            <span v-if="v$.note.maxLength.$invalid">{{
                                                v$.note.maxLength.$message
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <b-button class="mt-2 w-100" :disabled="state.isLoading" variant="primary" type="submit">
                                    <div class="d-flex justify-content-center">
                                        <span v-if="state.isLoading" class="d-flex align-items-center mr-2">
                                            <Loading />
                                        </span>
                                        {{ $t(state.apiId ? 'publicAPI.updateAPI' : 'publicAPI.openAPI') }}
                                    </div>
                                </b-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="card rounded-3">
                    <div class="card-header card-header-radius">
                        <h6 class="fs-16 mb-0 custom-h">{{ $t('publicAPI.list') }}</h6>
                    </div>
                    <div class="table-responsive">
                        <TableCommon :emptyText="$t('publicAPI.listEmpty')" :endpoint="ADMIN_API_PATH.ADMIN_PUBLIC_API"
                            ref="tablePublicAPI" :columns="columns" />
                    </div>
                </div>
            </div>
        </div>
        <b-modal size="sm" v-model="state.open" hide-footer class="v-modal-custom" centered>
            <template #title>
                <span class="font-weight-bold">{{ state.record.companyName }}</span>
            </template>
            <form @submit.prevent="updateExpired">
                <div class="row gap-2">
                    <div class="col-lg-12">
                        <label>{{ $t('publicAPI.endDate') }} <span class="text-danger">*</span></label>
                        <FlatPkr :placeholder="$t('placeholder.pleaseSelect', { field: $t('publicAPI.endDate') })"
                            class="form-control" :config="expiredDateConfig" v-model="state.record.endTime"></FlatPkr>
                        <div v-if="aV$.endTime.$error" class="invalid-feedback d-flex flex-column">
                            <span v-if="aV$.endTime.required.$invalid">{{
                                aV$.endTime.required.$message
                            }}</span>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <b-button type="submit" variant="primary" class="w-100">{{ $t('t-update') }}</b-button>
                    </div>
                </div>
            </form>
        </b-modal>
    </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import i18n from "@/i18n";
import { reactive, ref, h, computed, watch, onMounted } from 'vue';
import { ADMIN_API_PATH } from "@/constants";
import TableCommon from "@/components/common/TableCommonVue3.vue";
import Swal from "sweetalert2";
import { facilityService, publicApiService } from '@/services'
import { useStore } from "vuex";
import vSelect from 'vue-select';
import { cloneDeep, debounce, delay, values } from 'lodash';
import FlatPkr from 'vue-flatpickr-component'
import { helpers, maxLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import moment from 'moment';
import { copyText } from "vue3-clipboard";
import Loading from "@/components/Loading.vue";


export default {
    components: {
        Layout,
        PageHeader,
        TableCommon,
        vSelect,
        FlatPkr,
        Loading
    },
    setup() {
        const title = i18n.global.t('publicAPI.public')
        const items = [
            {
                text: i18n.global.t('t-setting_'),
                active: true
            },
            {
                text: i18n.global.t('publicAPI.public'),
                active: true,
            },
        ]
        const tablePublicAPI = ref(null)
        const store = useStore()
        const facilityId = computed(() => store.getters['settings/getFacilityId'])
        const startDatePicker = ref(null)
        const endDatePicker = ref(null)


        const apiClassName = {
            ACTIVE: "badge status-valid status",
            EXPIRED: "badge status-expired status",
            INACTIVE: "badge status-invalid status"
        }

        const i18nStatusMapping = {
            ACTIVE: i18n.global.t('t-enable'),
            INACTIVE: i18n.global.t('t-disable'),
            EXPIRED: i18n.global.t('t-expired')
        }

        const initDefault = {
            companyName: '',
            facilityId: '',
            startTime: null,
            endTime: null,
            note: ''
        }

        const initDefaultRecord = {
            ...initDefault,
            id: '',
            status: ''
        }

        const state = reactive({
            query: {
                page: 1,
                pageSize: 20,
                search: '',
                facilityId: ''
            },
            search: '',
            facilities: [],
            publicAPI: { ...initDefault },
            apiId: '',
            open: false,
            record: { ...initDefaultRecord },
            minStartDate: null,
            isLoading: false,
            prevFacilities: []
        })

        const publicAPI = computed(() => state.publicAPI)

        const rules = {
            companyName: {
                required: helpers.withMessage(i18n.global.t('field_required', { field: i18n.global.t('publicAPI.companyName') }), required),
                maxLength: helpers.withMessage(i18n.global.t('field_maxlength', { maxlength: 255, field: i18n.global.t('publicAPI.companyName') }), maxLength(255)),
            },
            facilityId: {
                required: helpers.withMessage(i18n.global.t('field_select_required', { field: i18n.global.t('publicAPI.facilityName') }), required),
            },
            startTime: {
                required: helpers.withMessage(i18n.global.t('field_select_required', { field: i18n.global.t('publicAPI.startDate') }), required)
            },
            note: {
                maxLength: helpers.withMessage(i18n.global.t('field_maxlength', { maxlength: 255, field: i18n.global.t('publicAPI.remarks') }), maxLength(255)),
            },
        }

        const expiredDateConfig = computed(() => ({
            dateFormat: "Y-m-d",
            enableTime: false,
            minDate: moment(state.record.startTime).toDate()
        }))

        const v$ = useVuelidate(rules, publicAPI)

        const endTimeRules = {
            endTime: {
                required: helpers.withMessage(i18n.global.t('field_select_required', { field: i18n.global.t('publicAPI.endDate') }), required)
            },
        }

        const record = computed(() => state.record)
        const minStartDate = computed(() => state.minStartDate)

        const aV$ = useVuelidate(endTimeRules, record)


        const startDateConfig = computed(() => ({
            dateFormat: "Y-m-d",
            enableTime: false,
            maxDate: publicAPI.value.endTime,
        }))

        const endDateConfig = computed(() => ({
            dateFormat: "Y-m-d",
            enableTime: false,
            minDate: minStartDate.value
        }))

        const columns = [
            {
                sortable: false,
                label: '',
                type: 'stt',
                maxWidth: 60,
                getShowColumn: (hasItem) => hasItem,
                renderCell: (api, rowIndex) => {
                    return h('span', rowIndex + 1)
                }
            },
            {
                key: 'companyName',
                sortable: true,
                label: i18n.global.t('publicAPI.companyName'),
                maxWidth: 150
            },
            {
                key: 'facility.name',
                sortable: true,
                label: i18n.global.t('publicAPI.facilityName'),
                maxWidth: 150
            },
            {
                key: 'apiKey',
                sortable: true,
                label: i18n.global.t('publicAPI.apiKey'),
                maxWidth: 200,
                renderCell: (api) => {
                    return h('div', { class: 'd-flex justify-content-between align-items-center' }, [h('span', api.apiKey), h('i', {
                        class: 'bx bxs-copy cursor-pointer fs-16', onClick: () => copyText(api.apiKey, undefined, () => {
                            Swal.fire({
                                title: "",
                                text: i18n.global.t('msg.copied'),
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1000,
                            });
                        })
                    })])
                }
            },
            {
                key: 'startTime',
                type: 'datetime',
                sortable: true,
                label: i18n.global.t('publicAPI.useStartedDate'),
                maxWidth: 120,
                renderCell: (api) => {
                    return h('div', { class: 'text-center' }, moment(api.startTime).format('YYYY-MM-DD'))
                }
            },
            {
                key: 'endTime',
                type: 'datetime',
                sortable: true,
                label: i18n.global.t('publicAPI.expiredDate'),
                maxWidth: 120,
                renderCell: (api) => {
                    if (api.endTime)
                        return h('div', { class: 'text-center' }, moment(api.endTime).format('YYYY-MM-DD'))
                    return h('div', { class: 'text-center' }, '-')
                }
            },
            {
                key: 'note',
                sortable: false,
                label: i18n.global.t('publicAPI.remarks'),
                maxWidth: 60,
                renderCell: (api) => {
                    return h('div', { class: 'text-center text-truncate' }, api.note || '-')
                }
            },
            {
                key: 'status',
                sortable: false,
                label: i18n.global.t('publicAPI.status'),
                maxWidth: 110,
                renderCell: (api) => {
                    const className = apiClassName[api.status]
                    return h('div', { class: 'd-flex justify-content-center align-items-center' }, [h('label', { class: `${className}`, size: 'sm' }, i18nStatusMapping[api.status])])
                }
            },
            {
                key: 'user.name',
                sortable: true,
                label: i18n.global.t('publicAPI.registerPerson'),
                maxWidth: 120
            },
            {
                key: 'action',
                type: 'action',
                getShowColumn: (hasItem) => hasItem,
                sortable: false,
                label: '',
                renderCell: (api) => {
                    return <div class="d-flex gap-2">
                        <b-button size="md" onClick={() => viewPublicAPI(api.id)} variant="primary">{i18n.global.t('t-edit')}</b-button>
                        <b-button size="md" onClick={() => deletePublicAPI(api)} variant="danger">{i18n.global.t('t-delete')}</b-button>
                    </div>
                }
            }
        ]

        const viewPublicAPI = async (apiId) => {
            try {
                let accepted = true;
                const empty = values(state.publicAPI).some(v => v)
                if (empty) {
                    const accept = await Swal.fire({
                        title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t('msg.override', { field: i18n.global.t('publicAPI.openAPI') })}</h2>`,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: i18n.global.t('yes'),
                        cancelButtonText: i18n.global.t('cancel'),
                    });
                    accepted = accept.value
                    if (!accepted) return;
                    state.publicAPI = cloneDeep(initDefault)
                }


                state.apiId = apiId;
                const response = await publicApiService.viewPublicAPI(apiId);
                let { startTime, endTime, companyName, facilityId, note, status } = response;
                const facility = await facilityService.getFacility(facilityId)
                state.facilities = [{ label: `${facility.name} (${facility.facilityIdStr})`, value: facilityId }]
                state.prevFacilities = cloneDeep(state.facilities)
                let startDate = moment(startTime)
                startTime = startDate.toDate()
                state.minStartDate = startDate.toDate()
                endDateConfig.value.minDate = startTime

                if (endTime) {
                    startDateConfig.value.maxDate = moment(endTime).toDate()
                }

                delay(() => {
                    state.publicAPI = {
                        status: status === 'ACTIVE' ? true : false,
                        startTime, endTime, companyName, facilityId, note
                    }
                })

            } catch (e) {
                console.log('viewPublicAPI', e);
            }
        }

        const deletePublicAPI = async (api) => {
            try {
                const accept = await Swal.fire({
                    title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t('deleted_message', { message: api.apiKey })}</h2>`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: i18n.global.t('yes'),
                    cancelButtonText: i18n.global.t('cancel'),
                });
                if (accept.value) {
                    await publicApiService.deletePublicAPI(api.id)
                    tablePublicAPI.value.refreshTable()
                }

            } catch (e) {
                console.log(e, 'deletePublicAPI')
            }
        }

        const createPublicAPI = debounce(async () => {
            v$.value.$touch();
            if (v$.value.$invalid) return;

            const isNew = state.apiId ? false : true
            state.isLoading = true
            try {

                if (isNew) {
                    await publicApiService.createPublicAPI({ ...state.publicAPI, companyName: state.publicAPI.companyName.trim() })
                    await Swal.fire({
                        title: "",
                        text: i18n.global.t('msg.saved'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    tablePublicAPI.value.refreshTable()
                    v$.value.$reset();
                    state.publicAPI = { ...initDefault }
                } else {
                    let status = state.publicAPI.status ? 'ACTIVE' : 'INACTIVE';
                    await publicApiService.updatePublicAPI(state.apiId, { ...state.publicAPI, status, companyName: state.publicAPI.companyName.trim() })
                    await Swal.fire({
                        title: "",
                        text: i18n.global.t('msg.saved'),
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    tablePublicAPI.value.refreshTable()
                    v$.value.$reset();
                    state.publicAPI = { ...initDefault }
                    state.apiId = ''
                }
            } catch (e) {
                let { message = 'error' } = e.data
                if (message.includes('exist')) {
                    message = i18n.global.t('publicAPI.existed')
                }
                await Swal.fire({
                    title: "",
                    text: message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
                console.log(e, 'createPublicAPI')
            } finally {
                state.isLoading = false
            }
        }, 500)

        const selectedFacilityChange = (selectedFacility) => {
            state.prevFacilities = [selectedFacility]
        }

        const selectFacilityClose = () => {
            if (!state.facilities.map(f => f.value).includes(state.publicAPI.facilityId) && state.prevFacilities.length > 0) {
                state.facilities = state.prevFacilities
            }
        }

        const debounceSearchFacility = debounce((searchValue = '') => searchFacility(searchValue), 500)

        const searchFacility = async (searchValue = '') => {
            state.search = searchValue
            if (searchValue || (!searchValue && !state.publicAPI.facilityId)) {
                getFacilities()
            }
        }

        const getFacilities = async () => {
            try {
                const params = {
                    search: state.search,
                    page: 1,
                    pageSize: 20,
                    sortBy: 'createdAt',
                    sortOrder: 'DESC'
                }

                const response = await facilityService.getFacilities(params)
                const data = response.data || []
                state.facilities = data.map(d => ({ label: `${d.name} (${d.facilityIdStr})`, value: d.id }))
            } catch (e) {
                console.log(e, 'getFacilities');
            }
        }

        const onStartDateIconClick = () => {
            startDatePicker.value.fp.toggle()
        }

        const onEndDateIconClick = () => {
            endDatePicker.value.fp.toggle()
        }

        const updateExpired = debounce(async () => {
            try {
                aV$.value.$touch();
                if (aV$.value.$invalid) return;

                const { companyName, endTime, startTime, facilityId, note, id } = state.record
                await publicApiService.updatePublicAPI(id, {
                    companyName, endTime, startTime, facilityId, note, status: 'VALID'
                })
                state.open = false;
                aV$.value.$reset();
                tablePublicAPI.value.refreshTable()
            } catch (e) {
                console.log('updateExpired', e)
            }
        }, 500)

        const startDateChange = (e) => {
            const { value } = e.target;
            state.minStartDate = moment(value).toDate()
        }

        onMounted(() => {
            getFacilities()
        })

        watch(() => state.publicAPI.facilityId, (newValue) => {
            if (!newValue) {
                state.search = ''
                getFacilities()
            }
        }, { deep: true })

        return {
            title,
            state,
            columns,
            createPublicAPI,
            ADMIN_API_PATH,
            tablePublicAPI,
            facilityId,
            debounceSearchFacility,
            startDateConfig,
            endDateConfig,
            startDatePicker,
            endDatePicker,
            onStartDateIconClick,
            onEndDateIconClick,
            v$,
            expiredDateConfig,
            updateExpired,
            aV$,
            items,
            startDateChange,
            selectedFacilityChange,
            selectFacilityClose
        }
    },
}
</script>

<style scoped lang="scss">
.style-chooser:deep {
    & .vs__search {
        &::placeholder {
            color: #878a9a !important;
            font-weight: 600 !important;
            opacity: 1 !important;
        }
    }

    &>span {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & .vs__actions {
        margin-right: 12px;
    }

    & .vs__search,
    .vs__selected {
        font-size: .85rem;
        margin-left: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & .vs__dropdown-toggle {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
</style>

<style lang="scss">
.status {
    padding: 0px;
    border-radius: 7px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    margin-top: 0.4rem;
}

.status-valid {
    background: #E7F8EF;
    color: #18BC62;
}

.status-expired {
    background: #FDEEEE;
    color: #E95657;
}

.status-invalid {
    background: #FCF7ED;
    color: #E4B755;
}

.custom-h {
    max-height: 18px;
}

.card-header-radius {
    border-radius: 10px 10px 0 0;
    font-size: 16px;
    color: var(--vz-heading-color);
}
</style>
